import { apiUrl, client } from "../modules/store";

export function getUsersLookups(lookupId) {
  var url = apiUrl + "/users/lookup";
  if (lookupId) {
    url = url + "?lookupId=" + lookupId;
  }
  return client.get(url);
}

export function getUsers(params) {
  return client.get(apiUrl + "/users", {
    params: params,
  });
}
export function getPortalUsers(params) {
  return client.get(apiUrl + "/users/portal", {
    params: params,
  });
}

export function addPortalUser(dto) {
  return client.post(apiUrl + "/users/portal", dto);
}

export function updatePortalUser(id, dto) {
  return client.put(`${apiUrl}/users/portal/${id}`, dto);
}
export function updatePortalUserSelf(dto) {
  return client.put(`${apiUrl}/users/portalSelf`, dto);
}

export function deletePortalUser(id, dto) {
  return client.delete(`${apiUrl}/users/portal/${id}`);
}
export function getUserById(userId) {
  return client.get(apiUrl + "/users/lookup/" + userId);
}

export function patchUser(id, dto) {
  return client.patch(apiUrl + "/users/" + id, dto);
}
export function getPermissionsForLookup(lookupType, lookupId) {
  return client.get(
    apiUrl +
      "/users/permissions?lookupType=" +
      lookupType +
      "&lookupId=" +
      lookupId
  );
}
export function getPermissionsForOrgUser(orgUserId) {
  return client.get(apiUrl + `/users/${orgUserId}/permissions`);
}
